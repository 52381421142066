:root{
    --navbar-height: 40px;
}
nav {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    text-shadow: 1px 1px 0px #00000060;
    box-shadow: 0px 2px 4px #00000020;
    height: 40px;
    background: linear-gradient(#d65027,#752006);
    border-bottom: 1px #5f1e0b solid;
    z-index: 100;
    overflow: hidden;
}
nav a {
    transition: all 0.3s ease-out;
    color: white;
}
nav a:hover {
    color: white;
    background: #ffffff2f;
}
/* Use to displace absolute components after the navbar */
.navbardisplacement{
    top: var(--navbar-height) !important; /* Navbar height */
}