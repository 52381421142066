html {
  max-width: 100%;
  height:100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
.background {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -999;
}

/* Animations */
.invisible{
  visibility: hidden;
}
.absolute{
  position: absolute;
}
.absoluteleftright{
  position: absolute;
  left: 0;
  right: 0;
  padding: inherit;
}
.relative{
  position: relative;
}

.plfadein{
  animation: fadein 0.5s forwards,
      slideinleft 0.5s forwards;
}
.plfadeout{
  animation: fadeout 0.5s forwards,
      slideoutleft 0.5s forwards;
}

.projectfadein{
  animation: fadein 0.5s forwards,
      slideinright 0.5s forwards;
}
.projectfadeout{
  animation: fadeout 0.5s forwards,
      slideoutright 0.5s forwards;
}
@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
@keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

/* Overlay */
.overlay {
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: #000000c0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.3s ease-out;
}
.fadeout {
  animation: fadeout 0.3s forwards;
}
.fadein {
  animation: fadein 0.3s forwards;
}

.pixelated{
  image-rendering: pixelated;
}